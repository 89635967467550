<template>
  <CRow>
    <CCol col="12" lg="9">
      <CCard>
        <CCardHeader class="d-flex align-items-center">
          <TMessage content="New post" bold />
          <div class="ml-auto d-flex">
            <TInputCheckbox
              class="my-auto mr-2"
              label="Publish"
              :checked="publish"
              @change="publish = !publish"
            />
            <TButton
              color="primary"
              content="Create new"
              icon="cil-plus"
              @click="create"
              :options="{ disabled: loading }"
            />
          </div>
        </CCardHeader>
        <CCardBody>
          <TInputText
            label="Title"
            placeholder="Title"
            :value.sync="title"
            class="mb-3"
          />
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="description"
          />
          <TMessage content="Content" bold class="mb-1" />
          <quillEditor v-model="content" class="editor"> </quillEditor>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol col="12" lg="3">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol col="12" lg="12" class="mb-2">
              <TInputFile
                accept="image/*"
                :value.sync="cover_file"
                :multiple="!cover_file.length ? false : true"
                class="w-100"
                theme="default"
                :maxFiles="1"
                label="Featured image"
              />
            </CCol>
            <CCol col="12" lg="12" class="mb-2">
              <TMessage content="Category" bold class="mb-2" />
              <CRow>
                <CCol col="10">
                  <SSelectPostCategory
                    :value.sync="category_id_selected"
                    :prepend="[]"
                    @change="category_selected = $event"
                  />
                </CCol>
                <CCol
                  ><TButtonAdd variant="" class="mt-1" @click="addCategory" />
                </CCol>
              </CRow>
              <div class="d-flex flex-wrap mt-2 mb-3">
                <div
                  color="primary"
                  class="d-flex align-items-center rounded p-2 py-1 mr-2 mb-1 border-info"
                  v-for="cat in categories"
                  :key="cat.id"
                >
                  <strong> {{ cat.name ? cat.name : cat.id }}</strong>
                  <TButtonRemove
                    class="ml-2"
                    noConfirm
                    @click="removeCat(cat.id)"
                  />
                </div>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      title: "",
      description: "",
      content: "",
      cover_file: [],
      category_id_selected: "",
      category_selected: {},
      categories: [],
      publish: true,
    };
  },
  computed: {
    ...mapGetters({
      loading: "helper.posts.loading",
    }),
  },
  methods: {
    create() {
      let data = {
        title: this.title,
        description: this.description,
        content: this.content,
        cover_file: this.cover_file.length && this.cover_file[0].file,
        publish: this.publish ? "1" : "0",
      };
      this.categories.forEach((cat, index) => {
        data = { ...data, [`categories[${index}]`]: cat.id };
      });
      const dataClean = this.lodash.pickBy(data, this.lodash.identity);
      this.$store.dispatch("helper.posts.create", dataClean).then((data) => {
        this.$router.push({ path: `${data.id}` });
      });
    },
    addCategory() {
      if (!this.category_id_selected) return;
      const itemIndex = this.categories.findIndex(
        (x) => x.id === this.category_id_selected
      );
      if (itemIndex == -1) {
        this.categories.push(this.category_selected);
        this.category_id_selected = "";
      } else this.category_id_selected = "";
    },
    removeCat(id) {
      const itemIndex = this.categories.findIndex((x) => x.id == id);
      this.categories.splice(itemIndex, 1);
    },
  },
};
</script>

<style scoped>
* >>> .ql-container {
  min-height: 200px;
}
</style>
